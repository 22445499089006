<template>
    <layout-admin-dashboard-view
            :showToolbar="false"
            :backRoute="{name: 'admin-user', params: {entityType:entityType}}">
        <template v-slot:title>
            {{translateTitleCase('core.user.editTitle')}}
        </template>

        <template v-slot:default>

            <crud-edit
                    :apiGetItemUrl="'user/'+userId"
                    :formPath="'form/user-user'"
                    :formAction="'user/'+userId"
                    :backRoute="{name: 'admin-user'}"
                    :successRedirect="{name: 'admin-user'}"
                    @error="handleError"
            >

            </crud-edit>

        </template>
    </layout-admin-dashboard-view>
</template>

<script>
    import CrudEdit from '@/client/components/crud/Edit.vue';
    import { useMeta } from 'vue-meta'
    import {useI18n} from "vue-i18n/index";


    export default {
        setup (props, setupContext ) {

            let {t} = useI18n();
            let {meta} = useMeta( {
                'title': t('admin.user.editView.title'),
            });

            return {meta}
        },
        props: {
            userId: {
                type: String,
                default: false,
            }
        },
        components: {CrudEdit},
        data: function () {
            return {};
        },
        methods: {
            handleError({context, data}) {
                console.log(context, data);

                // we have special treatment for validation error form server
                if (data.code != 422) {
                    return;
                }

                if (data.data.email) {
                    this.$s.ui.notification(this.translate(data.data.email), 'danger');
                }
            }
        }

    }
</script>

<style scoped lang="scss">

</style>
